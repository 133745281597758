import React from "react";
import './troupe.scss'

import cd from "../../img/portrait_Corentinjpg.jpg"
import ad from "../../img/portrait_Antoine.jpg"
import noimage from "../../img/noimage.webp"
import {RiMedalLine} from "react-icons/ri";
import {HiOutlineUser} from "react-icons/hi";
import {Link} from "react-router-dom";

export default function Troupe() {

    return (
        <div className={"containerTroupe"}>

            <div className={"intro"}>
                <h2 className={"titrePrimaire"}>Troupe XI ème Toulouse</h2>
                <div>
                    <a href="#maitrise">Maîtrise du Lion</a>
                    <Link to={"/patrouilles"}>Patrouilles</Link>
                    <Link to={"/chantTroupe"}>Chant de Troupe</Link>
                    <Link to={"/troupe/histoire"}>Histoire de la Troupe</Link>
                </div>
            </div>

            <div className={"video"}>

                <h1>Nouvelle activité !</h1>

                <iframe src="https://www.youtube.com/embed/VV_D4wFvaso?si=cL8CTtCWOGCXpdjQ"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>

            </div>

            <div className={"secondeContent"} id={"maitrise"}>
                <h1>Maîtrise de le troupe 2024/25</h1>
                <p className={"criTroupe"}>Cor meum eructavit verbum bonum !</p>
                <p className={"trad"}>De bonnes paroles jaillissent de mon coeur !</p>
                <div className={"presentationChef"}>
                    <Personne image={cd} nom={"Corentin DEPRECQ"} poste={"Chef de troupe"} ct={false}/>
                    <Personne image={ad} nom={"Antoine d'ESPARRON"} poste={"Assistant"} ct={false}/>
                    <Personne nom={"Ambroise DURAND"} poste={"Assistant"} ct={false}/>
                    <Personne nom={"Roman BOLZE"} poste={"Assistant"} ct={false}/>
                    <Personne nom={"Constantin HAUDEBERT"} poste={"Assistant"} ct={false}/>
                    <Personne nom={"Cyprien LAMY"} poste={"Assistant"} ct={false}/>
                </div>
            </div>

        </div>
    )
}

interface PersonneProps {
    image?: any
    nom: string
    ct: boolean
    poste: string
}

const Personne = (props: PersonneProps) => {

    return (
        <div className={"personne"}>
            <div className={"image"}>
                {props.image ? <img src={props.image} alt={"Portrait de " + props.nom}/> :
                    <img src={noimage} alt={"Portrait de " + props.nom}/>}
            </div>
            <div className={"poste"}>
                {props.ct ? <RiMedalLine/> : <HiOutlineUser/>}
                {props.poste}
            </div>
            <div className={"nom"}>
                {props.nom}
            </div>
        </div>
    )
}
