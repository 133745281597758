import React from "react";

import './contact.css'

export default function Contact() {
    return (
        <div className={"container_contact"}>


            <div className={"firstContentContact"}>
                <h1>Contact</h1>
            </div>

            <div className={"content_contact"}>
                <div>
                    <h2>Coordonnées du chef de groupe</h2>
                    <a href="mailto:chef.groupe.11eme.toulouse@gmail.com">chef.groupe.11eme.toulouse@gmail.com</a>
                </div>
                <div>
                    <h2>Coordonnées de la troupe</h2>
                    <a href="mailto:troupexiemetoulouse@gmail.com">troupexiemetoulouse@gmail.com</a>
                    <p>06 52 66 60 46</p>
                </div>
                <div>
                    <h2>Instagram</h2>
                    <a href="https://www.instagram.com/11_eme_toulouse?igsh=cmR2ODJoMTd1ZHJO">@11_eme_toulouse</a>
                </div>
            </div>

            <div className={"bug"}>
                <p>Pour signaler un bug ou suggérer une modificaton contacter l'addresse mail suivante</p>
                <a href="mailto:troupexiemetoulouse@gmail.com">troupexiemetoulouse@gmail.com</a>
            </div>
        </div>
    )
}