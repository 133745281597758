import React from "react";
import './hist.scss'

export interface HistoireModel {
    annee: string;
    nom: string;
}

export default function Histoire() {

    const hist: Array<HistoireModel> = [
        {annee: "2024-aujourd'hui", nom: "Corentin Deprecq"},
        {annee: "2022-2024", nom: "Pierre-Louis Dousset"},
        {annee: "2020-2022", nom: "Matthieu Groussin"},
        {annee: "2019-2020", nom: "Maxime de Poulpiquet"},
        {annee: "2018-2019", nom: "Renaud de Gabory"},
        {annee: "2016-2018", nom: "Guillaume de Catuélan"},
        {annee: "2015-2016", nom: "Paul Benderiter"},
        {annee: "2014-2015", nom: "Hubert Patier"},
        {annee: "2013-2014", nom: "Fabien Carpene"},
        {annee: "2012-2013", nom: "Alban Gomart"},
        {annee: "2011-2012", nom: "Charles Larger"},
        {annee: "2010-2011", nom: "Robin Lenseigne"},
        {annee: "2008-2009", nom: "?"},
        {annee: "2007-2008", nom: "?"},
        {annee: "2006-2007", nom: "?"},
        {annee: "2005-2006", nom: "?"},
        {annee: "2004-2005", nom: "?"},
        {annee: "2003-2004", nom: "?"},
        {annee: "2002-2003", nom: "?"},
        {annee: "2001-2002", nom: "?"},
        {annee: "2000-2001", nom: "?"},
        {annee: "1999-2000", nom: "?"},
        {annee: "1998-1999", nom: "?"},
        {annee: "1997-1998", nom: "?"},
        {annee: "1996-1997", nom: "?"},
        {annee: "1995-1996", nom: "?"},
        {annee: "1994-1995", nom: "?"},
        {annee: "1993-1994", nom: "?"},
        {annee: "1992-1993", nom: "?"},
        {annee: "1991-1992", nom: "?"},
        {annee: "1990-1991", nom: "?"},
        {annee: "1989-1990", nom: "?"},
        {annee: "1988-1989", nom: "?"},
        {annee: "1987-1988", nom: "?"},
        {annee: "1986-1987", nom: "?"},
        {annee: "1985-1986", nom: "?"},
        {annee: "1984-1985", nom: "?"},
        {annee: "1983-1984", nom: "?"},
        {annee: "1982-1983", nom: "?"},
        {annee: "1981-1982", nom: "?"},
        {annee: "1980-1981", nom: "?"},
        {annee: "1979-1980", nom: "?"},
        {annee: "1978-1979", nom: "?"},
        {annee: "1977-1978", nom: "?"},
        {annee: "1976-1977", nom: "?"},
        {annee: "1975-1976", nom: "?"},
    ]

    return (
        <div className={"content_histoire"}>

            <div className={"intro"}>
                <h2 className={"titrePrimaire"}>Histoire de la 11ème Toulouse</h2>
            </div>

            <h2 className={"secondaryTitre"}>Liste des chefs de Troupe</h2>
            <div className={"hist"}>
                {
                    hist.map((hist: HistoireModel) => (
                        <div>
                            <div className={"left"}></div>
                            <div className={"right"}>
                                <h3>{hist.annee}</h3>
                                <h2>{hist.nom}</h2>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}