import React from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import Accueil from "./component/Accueil/Accueil";
import Troupe from "./component/Troupe/Troupe";
import Contact from "./component/Contact/Contact";
import Login from "./component/Connection/Login";
import Patrouilles from "./component/Troupe/Patrouille/Patrouilles";
import ChantTroupe from "./component/Troupe/Chant/ChantTroupe";
import Photo from "./component/Photo/Photo";
import Meute from "./component/Meute/Meute";
import Docs from "./component/Docs/Docs";
import Detail from "./component/Docs/Detail";
import TableauImage from "./component/Generateur/TableauImage/TableauImage";
import Imaginaire from "./component/Troupe/Imaginaire/Imaginaire";
import Histoire from "./component/Troupe/Histoire/Histoire";

export default function NavRoutes() {

    const location = useLocation()

    return (
        <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Accueil/>}/>
            <Route path="/troupe" element={<Troupe/>}/>
            <Route path="/troupe/imaginaire" element={<Imaginaire/>}/>
            <Route path="/troupe/histoire" element={<Histoire/>}/>
            <Route path="/contact" element={<Contact/>}/>
            <Route path="/patrouilles" element={<Patrouilles/>}/>
            <Route path="/chantTroupe" element={<ChantTroupe/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path={"/photo"} element={<Photo/>}/>
            <Route path={"/meute"} element={<Meute/>}/>
            <Route path={"/docs"} element={<Docs/>}/>
            <Route path={"/docs/:id"} element={<Detail/>}/>
            <Route path={"/generateur/tableauImage"} element={<TableauImage/>}/>
        </Routes>
    )
}