import React, {useContext, useEffect, useState} from "react";
import croix from "../../img/croixSansFond.png"
import './navbar.css'
import {Link} from "react-router-dom";
import {FiHome, FiLogIn, FiLogOut, FiPhone, FiUsers} from "react-icons/fi";
import AuthContext from "../../context/AuthContext";
import {HiOutlinePhoto} from "react-icons/hi2";

export default function Navbar() {

    const [color, _] = useState("#FFFFFF")

    const [open, setOpen] = useState(false)

    useEffect(() => {
        window.addEventListener("scroll", _ => {
            const a = document.getElementById("shadow");

            if (a !== null) {
                if (window.scrollY >= 10) {
                    a.classList.add("navShadow")
                } else {
                    a.classList.remove("navShadow")
                }
            }
        })

    }, [])


    return (
        <div className={'navbar ' + (open ? "navbar_open" : "")} id={"shadow"}>
            <div className={"leftContentNav"}>
                <img src={croix} alt="Croix scout"/>
                <NavBar color={color} open={open} close={() => setOpen(false)}/>
            </div>
            <div>
                <h2 style={{color: color}}>2024/2025</h2>
            </div>
        </div>)
}

const NavBar = (props: any) => {

    const {isConnected} = useContext(AuthContext)

    return (
        // <div style={{display:  window.innerWidth < 1610 ? (props.open ? "flex" : "none") : "flex"}}>
        <div>
            {/*<TextAlignLeft strokeWidth={2} size={30} className={"iconNav"} color={props.color}*/}
            {/*               onClick={() => setOpen(true)}/>*/}
            <div className={"hiddenNavBar"}>
                {/*<img src={croixWF} alt="Croix scout"/>*/}
                <div className={"content_item_nav"} onClick={() => props.close()}>
                    <Link to={"/"}>
                        <div className={"principalNav"}><FiHome className={"icon_nav"}/>Accueil</div>
                    </Link>
                </div>
                <div className={"content_item_nav"} onClick={() => props.close()}>
                    <Link to={"/troupe"}>
                        <div className={"principalNav"}><FiUsers className={"icon_nav"}/>Troupe</div>
                    </Link>
                </div>
                <div className={"content_item_nav"} onClick={() => props.close()}>
                    <Link to={"/meute"}>
                        <div className={"principalNav"}><FiUsers className={"icon_nav"}/>Meute</div>
                    </Link>
                </div>
                <div className={"content_item_nav"} onClick={() => props.close()}>
                    <Link to={"/docs"}>
                        <div className={"principalNav"}><FiUsers className={"icon_nav"}/>Documentation</div>
                    </Link>
                </div>
                <div className={"content_item_nav"} onClick={() => props.close()}>
                    <Link to={"/photo"}>
                        <div className={"principalNav"}><HiOutlinePhoto className={"icon_nav"}/>Photo</div>
                    </Link>
                </div>
                <div className={"content_item_nav"} onClick={() => props.close()}>
                    <Link to={"/contact"}>
                        <div className={"principalNav"}><FiPhone className={"icon_nav"}/>Contact</div>
                    </Link>
                </div>
                <div className={"content_item_nav"} onClick={() => props.close()}>
                    <Link to={"/login"}>
                        <div className={"principalNav"}>
                            {
                                isConnected ? (
                                    <><FiLogOut className={"icon_nav"}/>Me déconnecter</>
                                ) : (
                                    <><FiLogIn className={"icon_nav"}/>Me connecter</>
                                )
                            }
                        </div>
                    </Link>
                </div>
            </div>
        </div>)
}